import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import styled, { css } from "styled-components";
import AppContext from "../AppContext";
import InstagramIcon from "../assets/svgs/instagram.svg";
import TwitterIcon from "../assets/svgs/twitter.svg";
import EpisodeIcon from "../assets/svgs/icon-microphone.svg";
import RecipeIcon from "../assets/svgs/icon-inbox-full.svg";
import MoreLinkIcon from "../assets/svgs/icon-external-window.svg";
import heroImageUrl from "../assets/images/hero_image.jpg";
import NewsletterSignup from "../components/homepage/NewsletterSignup";
import { useStaticQuery, graphql } from "gatsby";
import RecipeBox from "../components/common/RecipeBox";
import EpisodeBox from "../components/common/EpisodeBox";
import Header from "../components/common/Header";
import UnstyledLink from "../components/common/UnstyledLink";
import InteractiveButton from "../components/common/InteractiveButton";
import Seo from "../components/common/Seo";
import useSiteMetadata from "../useSiteMetadata";

const Hero = styled.section`
  display: grid;
  grid-template-columns: 40% 60%;
  background: url(${heroImageUrl});
  background-size: cover;
  background-position-y: center;
  @media screen and (min-width: 1024px) {
    min-height: 500px;
  }
`;

const HeroContent = styled.div`
  grid-column-start: 2;
  padding: 1em 0.5em 0.5em 1em;
  margin: auto;
`;

const Description = styled.h2`
  line-height: 1.8em;
  font-size: 1.2em;
  font-weight: ${(props) => props.theme.fonts.weights.regular};
  margin: 0;
  min-width: 200px;
  width: 80%;
  text-align: center;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    width: 20rem;
    font-size: 1.4em;
  }
  @media screen and (min-width: 1024px) {
    text-align: unset;
    width: 35rem;
    font-size: 2em;
  }
`;

const HeroCtas = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;

const ctaCommon = css`
  display: block;
  width: 180px;
  height: 45px;
  margin: 1em auto 0em auto;
  border-radius: 5px;
  @media screen and (min-width: 1024px) {
    margin: 1em 1em 0em 0em;
  }
`;

const SubscribeButton = styled(InteractiveButton)`
  ${ctaCommon}
  cursor: pointer;
  background: ${(props) => props.theme.colors.accents1};
  font-size: 1em;
  padding: 0.5em 2em;
`;

const NewsletterLink = styled.a`
  ${ctaCommon}
  background: transparent;
  color: ${(props) => props.theme.colors.text};
  text-decoration: none;
  border: 5px solid ${(props) => props.theme.colors.accents1};
  box-sizing: border-box;
  line-height: 35px;
  text-align: center;
  &:visited {
    color: ${(props) => props.theme.colors.text};
  }
`;

const ContentSection = styled.section`
  padding: 2em;
  max-width: 1200px;
  margin: auto;
`;

const ContentSectionTitle = styled.div`
  display: flex;
  align-items: center;
  margin: 2em 0 1em 0;
  color: hsl(0, 0%, 23%);
  svg {
    margin-right: 0.5em;
    height: 22px;
    width: auto;
    .primary {
      fill: hsl(0, 0%, 45%);
    }
    .secondary {
      fill: hsl(0, 0%, 70%);
    }
  }
`;

const ContentBoxes = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2em;
  & > * {
    width: 100%;
  }

  @media screen and (min-width: 400px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 1024px) {
    grid-gap: 4em;
    justify-items: center;
  }
`;

const MoreContentLink = styled.div`
  display: flex;
  margin-top: 3em;
  justify-content: flex-end;
  svg {
    height: 22px;
    width: auto;
    margin-left: 0.5em;
    .primary {
      fill: hsl(0, 0%, 70%);
    }
    .secondary {
      fill: hsl(0, 0%, 55%);
    }
  }
`;

const SocialLinksContainer = styled.div`
  height: 40px;
  margin-top: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    height: 100%;
    margin-left: 1rem;
    &:first-child {
      margin-left: 0rem;
    }
  }
  svg {
    height: 100%;
    width: auto;
    path {
      fill: ${(props) => props.theme.colors.accents1} !important;
    }
  }

  @media screen and (min-width: 1024px) {
    justify-content: flex-start;
  }
`;

const StyledHomePage = styled.div`
  height: 100vh;
`;

type ContentResponse = {
  episodes: {
    nodes: {
      frontmatter: {
        title: string;
        description: string;
        published: string;
      };
      fields: {
        slug: string;
      };
    }[];
  };
  recipes: {
    nodes: {
      frontmatter: {
        title: string;
        image: {
          childImageSharp: {
            fluid: {
              base64: string;
              aspectRatio: number;
              src: string;
              srcSet: string;
              sizes: string;
            };
          };
        };
      };
      fields: {
        slug: string;
      };
    }[];
  };
};

type HomePageProps = {
  openSubscribeMenu: () => void;
};

const HomePage: React.FC<HomePageProps> = React.memo(
  ({ openSubscribeMenu }) => {
    const { description } = useSiteMetadata();
    const data = useStaticQuery<ContentResponse>(graphql`
      {
        episodes: allMdx(
          filter: { slug: { glob: "episodes/*" } }
          sort: { fields: frontmatter___number, order: DESC }
          limit: 6
        ) {
          nodes {
            frontmatter {
              title
              description
              published
            }
            fields {
              slug
            }
          }
        }
        recipes: allMdx(
          filter: { slug: { glob: "recipes/*" } }
          sort: { fields: frontmatter___published, order: DESC }
        ) {
          nodes {
            frontmatter {
              title
              image {
                childImageSharp {
                  fluid {
                    base64
                    aspectRatio
                    src
                    srcSet
                    sizes
                  }
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    `);
    const episodes = data.episodes.nodes.map((node) => ({
      title: node.frontmatter.title,
      description: node.frontmatter.description,
      published: node.frontmatter.published,
      slug: node.fields.slug,
    }));
    const recipes = data.recipes.nodes.map((node) => ({
      title: node.frontmatter.title,
      slug: node.fields.slug,
      image: node.frontmatter.image,
    }));

    return (
      <>
        <Helmet title="Foodie Buddies Pod" />
        <Seo title="Foodie Buddies Pod" />
        <StyledHomePage>
          <Header />
          <Hero>
            <HeroContent>
              <Description>{description}</Description>
              <HeroCtas>
                {/* <SubscribeButton onClick={openSubscribeMenu}>
                  Subscribe
                </SubscribeButton> */}
                <UnstyledLink to="/episodes">
                  <SubscribeButton>View Episodes</SubscribeButton>
                </UnstyledLink>
                <NewsletterLink href="#newsletter-signup">
                  Join Our Newsletter
                </NewsletterLink>
              </HeroCtas>
              <SocialLinksContainer>
                <a href="https://www.instagram.com/foodiebuddiespod/">
                  <InstagramIcon />
                </a>
                <a href="https://twitter.com/foodiebudspod">
                  <TwitterIcon />
                </a>
              </SocialLinksContainer>
            </HeroContent>
          </Hero>
          <ContentSection>
            <UnstyledLink to="/episodes">
              <ContentSectionTitle>
                <EpisodeIcon />
                <h3>Podcast Episodes</h3>
              </ContentSectionTitle>
            </UnstyledLink>
            <ContentBoxes>
              {episodes.map((episode) => (
                <EpisodeBox
                  key={episode.slug}
                  title={episode.title}
                  slug={episode.slug}
                  description={episode.description}
                  published={episode.published}
                />
              ))}
            </ContentBoxes>
            <MoreContentLink>
              <UnstyledLink to="/episodes">More Episodes</UnstyledLink>
              <MoreLinkIcon />
            </MoreContentLink>
          </ContentSection>
          <ContentSection>
            <UnstyledLink to="/recipes">
              <ContentSectionTitle>
                <RecipeIcon />
                <h3>Recipes</h3>
              </ContentSectionTitle>
            </UnstyledLink>
            <ContentBoxes>
              {recipes.map((recipe) => (
                <RecipeBox
                  key={recipe.slug}
                  title={recipe.title}
                  slug={recipe.slug}
                  image={recipe.image}
                />
              ))}
            </ContentBoxes>
            <MoreContentLink>
              <UnstyledLink to="/recipes">More Recipes</UnstyledLink>
              <MoreLinkIcon />
            </MoreContentLink>
          </ContentSection>
          <div id="newsletter-signup">
            <NewsletterSignup />
          </div>
        </StyledHomePage>
      </>
    );
  }
);

const HomePageContainer: React.FC = () => {
  const { openSubscribeMenu } = useContext(AppContext);

  return <HomePage openSubscribeMenu={openSubscribeMenu} />;
};

export default HomePageContainer;
