import React, { useState } from "react";
import styled, { css } from "styled-components";

type StyledInteractiveButtonProps = {
  clicking: boolean;
};

const StyledInteractiveButton = styled.button<StyledInteractiveButtonProps>`
  ${(props) =>
    !props.clicking &&
    css`
      box-shadow: 0px 2px 4px 0px hsl(0, 16%, 30%);
    `}
`;

type InteractiveButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  className?: string;
};

const InteractiveButton: React.FC<InteractiveButtonProps> = ({
  children,
  ref,
  ...rest
}) => {
  const [buttonClicked, setButtonClicked] = useState(false);
  return (
    <>
      <StyledInteractiveButton
        {...rest}
        onMouseDown={() => setButtonClicked(true)}
        onMouseUp={() => setButtonClicked(false)}
        onTouchStart={() => setButtonClicked(true)}
        onTouchEnd={() => setButtonClicked(false)}
        clicking={buttonClicked}
      >
        {children}
      </StyledInteractiveButton>
    </>
  );
};

export default InteractiveButton;
