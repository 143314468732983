import React, { useState, useRef } from "react";
import styled from "styled-components";
import InteractiveButton from "../common/InteractiveButton";
import MailIcon from "../../assets/svgs/icon-mail.svg";

const JoinUs = styled.h3`
  margin: 0 auto;
  text-align: center;
`;

const Text = styled.p`
  max-width: 25em;
  margin: 0.5em auto 2em auto;
  line-height: 1.6em;
  text-align: center;
`;

const SignupForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
`;

const Input = styled.input`
  display: block;
  border: none;
  border-bottom: 5px solid ${(props) => props.theme.colors.accents1};
  margin-bottom: 1em;
  padding: 0.75em;
  outline: none;
  border-radius: 5px;
`;

const SignUpButton = styled(InteractiveButton)`
  background: ${(props) => props.theme.colors.accents1};
  padding: 0.75em 1.5em;
  border-radius: 5px;
`;

const StyledNewsletterSignup = styled.div`
  background: #d3ddd4;
  padding: 1.5em;
`;

const Content = styled.div`
  max-width: 500px;
  margin: auto;
  svg {
    height: 30px;
    margin: 1em auto;
    display: block;
    .primary {
      fill: hsl(0, 0%, 50%);
    }
    .secondary {
      fill: hsl(0, 0%, 65%);
    }
  }
`;

const NewsletterSignup: React.FC = () => {
  return (
    <StyledNewsletterSignup>
      <Content>
        <MailIcon />
        <JoinUs>Join Us</JoinUs>
        <Text>
          Enter your email to get regular updates on new episodes, recipes, blog
          posts, and more.
        </Text>
        <SignupForm
          method="post"
          action="https://www.aweber.com/scripts/addlead.pl"
        >
          <input type="hidden" name="listname" value="awlist5674569" />
          <Input type="text" name="name" placeholder="Name" required />
          <Input
            type="text"
            name="email"
            placeholder="Email Address"
            required
          />
          <SignUpButton>Sign Up</SignUpButton>
        </SignupForm>
      </Content>
    </StyledNewsletterSignup>
  );
};

export default NewsletterSignup;
